@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy'), url(/public/assets/fonts/Gilroy/Gilroy-Regular.otf) format('opentype');
}

@font-face {
    font-family: 'Gilroy';
    font-weight: bold;
    src: local('Gilroy'), url(/public/assets/fonts/Gilroy/Gilroy-Bold.otf) format('opentype');
}
